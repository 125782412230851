html,
body,
#root {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	color: #21d6d6;

	background-color: #222222;
	font-family: "Archivo Black", sans-serif;
	text-align: center;
}

a,
a:active,
a:visited {
	color: inherit;
	text-decoration: inherit;
}

::selection {
	background: #f86b6b; /* WebKit/Blink Browsers */
}
::-moz-selection {
	background: #f86b6b; /* Gecko Browsers */
}

body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background-color: rgba(0, 0, 0, 0);
}

h1 {
	font-size: 250%;
	margin: 0.6em 0.2em 0em 0.2em;
	text-align: center;
	text-transform: uppercase;
	font-family: "Alfa Slab One", cursive;
	text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
		2px 2px 0 #000;
}

h2 {
	font-size: 100%;
	margin: 0.3em 0.2em 0em 0.2em;
}
#top {
	height: 70%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	z-index: 0;
}

#render_element {
	filter: invert(0.8);
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 1;
}

#bottom {
	height: 30%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	background-color: inherit;
	z-index: 2;
}

#inputs {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: inherit;
	height: 50%;
}

#vert_input {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

#input_height {
	width: 9em;
	height: 3em;
	margin: 0.3em;
}

#column_inputs {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

#bottom_text {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

label {
	display: none;
}
/* #region  input styling from http://danielstern.ca/range.css */

input[type="range"] {
	width: 100%;
	margin: 6.5px 0;
	background-color: transparent;
	-webkit-appearance: none;
}
input[type="range"]:focus {
	outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
	background: #21d6d6;
	border: 0.2px solid #010101;
	border-radius: 25px;
	width: 100%;
	height: 6px;
	cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
	margin-top: -6.7px;
	width: 19px;
	height: 19px;
	background: #21d6d6;
	border: 0.2px solid #000000;
	border-radius: 27px;
	cursor: pointer;
	-webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
	background: #f8fefe;
}
input[type="range"]::-moz-range-track {
	background: #21d6d6;
	border: 0.2px solid #010101;
	border-radius: 25px;
	width: 100%;
	height: 6px;
	cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
	width: 19px;
	height: 19px;
	background: #21d6d6;
	border: 0.2px solid #000000;
	border-radius: 27px;
	cursor: pointer;
}
input[type="range"]::-ms-track {
	background: transparent;
	border-color: transparent;
	border-width: 6.5px 0;
	color: transparent;
	width: 100%;
	height: 6px;
	cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
	background: #000000;
	border: 0.2px solid #010101;
	border-radius: 50px;
}
input[type="range"]::-ms-fill-upper {
	background: #21d6d6;
	border: 0.2px solid #010101;
	border-radius: 50px;
}
input[type="range"]::-ms-thumb {
	width: 19px;
	height: 19px;
	background: #21d6d6;
	border: 0.2px solid #000000;
	border-radius: 27px;
	cursor: pointer;
	margin-top: 0px;
	/*Needed to keep the Edge thumb centred*/
}
input[type="range"]:focus::-ms-fill-lower {
	background: #21d6d6;
}
input[type="range"]:focus::-ms-fill-upper {
	background: #f8fefe;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
	/* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
	input[type="range"] {
		margin: 0;
		/*Edge starts the margin from the thumb, not the track as other browsers do*/
	}
}

/* #endregion */
